
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import MsAddGroupModal from "@/components/student/MsAddGroupModal.vue";
import MsEditGroupModal from "@/components/student/MsEditGroupModal.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import { Student } from "@/entities/student";
import { Classroom } from "@/entities/classroom";
import { School } from "@/entities/school";
import { CustomProperty } from "@/entities/custom_property";
import { Group } from "@/entities/group";
import { Getter, State } from "@/store/helper";
import { Options, Vue } from "vue-class-component";
import { convertToGroupTableData, groupsTableDefinition } from "@/utils/table";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MsAddGroupModal,
    MsEditGroupModal,
    MsSendModal
  }
})
export default class GroupList extends Vue {
  tabs: TabItem[] = [
    {
      text: "生徒一覧",
      link: "/student"
    },
    {
      text: "グループ管理",
      link: "/group"
    },
    {
      text: "メッセージ予約",
      link: "/message"
    }
  ];
  currentTabIndex = 1;
  searchKeyword = "";
  load = false;
  isDisplayedAddGroupModal = false;
  isDisplayedEditGroupModal = false;
  selectedGroupId = "";
  isDisplayedSendModal = false;
  definitions = groupsTableDefinition;

  @State("students") students!: Student[];
  @State("classrooms") classrooms!: Classroom[];
  @State("schools") schools!: School[];
  @State("customProperties") customProperties!: CustomProperty[];
  @State("groups") groups!: Group[];
  @Getter("isAdmin") isAdmin!: boolean;

  get selectedGroup(): Group | null {
    const matchGroups = this.groups.filter(
      group => group.ref.id === this.selectedGroupId
    );
    if (matchGroups.length > 0) {
      return matchGroups[0];
    } else {
      return null;
    }
  }

  get datas(): { [key: string]: string | number | boolean }[] {
    return this.groups.map(group => convertToGroupTableData(group));
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(
      data =>
        Object.keys(data).includes("name") &&
        data.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  showAddModal() {
    this.isDisplayedAddGroupModal = true;
  }

  closeAddModal() {
    this.isDisplayedAddGroupModal = false;
  }

  showEditModal(id: string) {
    this.selectedGroupId = id;
    this.isDisplayedEditGroupModal = true;
  }

  showSendModal() {
    this.isDisplayedSendModal = true;
  }

  closeSendModal() {
    this.isDisplayedSendModal = false;
  }
}
